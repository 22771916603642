export const VERTICAL = {
  HEALTH_INSURANCE: 'Health Insurance',
  LIFE_INSURANCE: 'Life Insurance',
  ENERGY: 'Energy',
  PERSONAL_LOANS: 'Personal Loans',
  CREDIT_CARDS: 'Credit Cards',
  HOME_LOANS: 'Home Loans',
  CAR_LOANS: 'Car Loans',
  CAR_INSURANCE: 'Car Insurance',
  DEFAULT: 'Default'
}

export const VERTICAL_LIST = Object.values(VERTICAL).filter(vertical => vertical !== 'Default')

export const VERTICALS = {
  Default: {
    comparisonPage: '/health-insurance/quotes-results/',
    contactEmail: 'hello@compareclub.com.au',
    customQuickQuotePage: false,
    dealAlertsFormPage: '/dashboard/deal-alerts/form/health-insurance/',
    formPage: '/health-insurance/quote/form/step1/',
    globalPhoneNumber: '1300 11 2582',
    logSource: '',
    partnershipsEmail: 'hello@compareclub.com.au',
    phoneNumber: '1300 11 2582',
    phoneNumberDisplay: '1300 11 CLUB',
    productPage: '/',
    requestCallbackTankyouMessage: 'We have received your callback request. One of our <strong>experts</strong> will be in touch with you.',
    requestCallbackText: 'Our experts can provide you with free personal advice. Let us call you.',
    schedule: 'Mon-Fri 8am-8pm AEST',
    schedule_weekend: '',
    speakWithUsText: 'Our experts can provide you with free personal advice.',
    stickyCta: false,
    title: 'Health Insurance',
    verticalTabletTitle: '',
    crossApp: false,
    complaintsEmail: 'complaints@compareclub.com.au'
  },

  [VERTICAL.HEALTH_INSURANCE]: {
    comparisonPage: '/health-insurance/quotes-results/',
    customQuickQuotePage: false,
    dealAlertsFormPage: '/dashboard/deal-alerts/form/health-insurance/step1/',
    formPage: '/health-insurance/quote/form/step1/',
    logSource: 'HIC-presubmit',
    ovhcPage: '/health-insurance/visitors-visa-ovhc/',
    phoneNumber: '1300 757 819',
    phoneNumberDisplay: '1300 757 819',
    comparisonPageNumber: '0411 443 823',
    productPage: '/health-insurance/',
    requestCallbackTankyouMessage: 'We have received your callback request. One of our <strong>experts</strong> will be in touch with you.',
    requestCallbackText: 'Our experts can provide you with Health Insurance quotes. Let us call you.',
    schedule: 'Mon-Fri 9am-6pm AEST',
    schedule_weekend: '',
    speakWithUsText: 'Our experts can provide you with free personal advice.',
    stickyCta: false,
    title: 'Health Insurance',
    verticalTabletTitle: 'Health',

    icon: getComponent('IconsVerticalsHomeHealth'),
    home_icon: getComponent('IconsVerticalsHomeHealth'),
    guides_index_title: 'Health',
    label: 'Health Insurance',
    cta_id: 'health_insurance_quote_btn',
    text: 'Get a quote in under 2 minutes.',
    badgeInfo: 'Save $300*',
    url: '/health-insurance/quote/form/step0/',
    guidesUrl: '/health-insurance/guides/',
    productPageUrl: '/health-insurance/',
    vaultFormPage: '/dashboard/vault/form/health-insurance/step/1',
    crossApp: false
  },

  [VERTICAL.LIFE_INSURANCE]: {
    comparisonPage: '/life-insurance/quote/comparison/',
    customQuickQuotePage: false,
    dealAlertsFormPage: '/dashboard/deal-alerts/form/life-insurance/',
    formPage: '/life-insurance/quote/form/step1/',
    incomeProtectionFormPage: '/life-insurance/income-protection/quote/',
    logSource: 'lic-advisor',
    phoneNumber: '1300 598 418',
    productPage: '/life-insurance/',
    requestCallbackTankyouMessage: 'We have received your callback request and will be in touch with you.',
    requestCallbackText: 'Our experts can provide you with free consultation. Let us call you.',
    schedule: 'Mon-Thu 9:30am-6pm AEST',
    schedule_weekend: 'Fri 9am-5pm AEST',
    speakWithUsText: 'We can help you save time and effort in choosing life cover',
    stickyCta: false,
    title: 'Life Insurance',
    verticalTabletTitle: 'Life',

    icon: getComponent('IconsVerticalsLife'),
    home_icon: getComponent('IconsVerticalsHomeLife'),
    guides_index_title: 'Life',
    label: 'Life Insurance',
    cta_id: 'life_insurance_quote_btn',
    text: 'Get a quote in less than a minute.',
    url: '/life-insurance/quote/form/step1/',
    guidesUrl: '/life-insurance/guides/',
    productPageUrl: '/life-insurance/',
    vaultFormPage: '/dashboard/vault/form/life-insurance/step/1',
    crossApp: false
  },

  [VERTICAL.ENERGY]: {
    comparisonPage: '/energy/quote/thank-you/',
    customQuickQuotePage: true,
    dealAlertsFormPage: '/dashboard/deal-alerts/form/energy/',
    equestCallbackTankyouMessage: 'We have received your callback request. One of our <strong>experts</strong> will be in touch with you.',
    formPage: '/energy/quote/form/step1/',
    logSource: '',
    phoneNumber: '1300 836 816',
    productPage: '/energy/',
    requestCallbackText: 'Our experts can provide you with free personal advice. Let us call you.',
    schedule: 'Mon-Fri 9am-5pm AEST',
    schedule_weekend: '',
    speakWithUsText: 'Our experts can provide you with free personal advice.',
    stickyCta: false,
    title: 'Energy',
    verticalTabletTitle: 'Energy',

    icon: getComponent('IconsVerticalsEnergy'),
    home_icon: getComponent('IconsVerticalsHomeEnergy'),
    guides_index_title: 'Energy',
    label: 'Energy',
    cta_id: 'electricity_gas_quote_btn',
    text: 'Get a quote in less than a minute.',
    badgeInfo: 'Save $266 to $553**',
    url: '/energy/quote/form/step1/',
    guidesUrl: '/energy/guides/',
    productPageUrl: '/energy/',
    vaultFormPage: '/dashboard/vault/form/energy/step/1',
    crossApp: false
  },

  [VERTICAL.HOME_LOANS]: {
    calculatorPage: '/home-loans/calculators/',
    cashRates: {
      variableRate: '4.46%',
      comparisonRate: '4.72%',
      date: '16/12/2022',
      interestRateImage: 'rba-rates-v10.svg'
    },
    comparisonPage: '/home-loans/quote/thank-you/',
    customQuickQuotePage: false,
    dealAlertsFormPage: '/dashboard/deal-alerts/form/home-loans/step1/',
    formPage: '/home-loans/quote/form/step1/',
    logSource: 'HLA-presubmit',
    phoneNumber: '1300 190 429',
    productPage: '/home-loans/',
    requestCallbackTankyouMessage: 'We have received your callback request. One of our <strong>experts</strong> will be in touch with you.',
    requestCallbackText: 'Our experts can provide you with free personal advice. Let us call you.',
    schedule: 'Mon-Fri 9am-5:30pm AEST',
    schedule_weekend: '',
    speakWithUsText: 'Our experts can provide you with free personal advice.',
    stickyCta: false,
    title: 'Home Loans',
    verticalTabletTitle: 'Home',

    icon: getComponent('IconsVerticalsHomeLoan'),
    home_icon: getComponent('IconsVerticalsHomeHouseLoans'),
    guides_index_title: 'Home Loans',
    label: 'Home Loans',
    cta_id: 'home_loans_quote_btn',
    text: 'Get a quote in less than a minute.',
    url: '/home-loans/quote/form/step1/',
    guidesUrl: '/home-loans/guides/',
    productPageUrl: '/home-loans/',
    vaultFormPage: '/dashboard/vault/form/home-loan/step/1',
    crossApp: false
  },

  [VERTICAL.PERSONAL_LOANS]: {
    comparisonPage: '',
    customQuickQuotePage: false,
    dealAlertsFormPage: '/dashboard/deal-alerts/form/personal-loans/',
    formPage: '/personal-loans/quote/form/step1',
    logSource: '',
    phoneNumber: '',
    productPage: '/personal-loans/',
    requestCallbackTankyouMessage: 'We have received your callback request. One of our <strong>experts</strong> will be in touch with you.',
    requestCallbackText: 'Our experts can provide you with free personal advice. Let us call you.',
    schedule: '',
    schedule_weekend: '',
    speakWithUsText: 'Our experts can provide you with free personal advice.',
    stickyCta: false,
    title: 'Personal Loans',
    verticalTabletTitle: '',

    icon: getComponent('IconsVerticalsPersonalLoan'),
    home_icon: getComponent('IconsVerticalsHomePersonalLoan'),
    guides_index_title: 'Personal Finance',
    label: 'Personal Loans',
    cta_id: 'personal_loans_quote_btn',
    text: 'Get a quote in less than a minute.',
    url: 'https://personalloans.compareclub.com.au/apply/',
    guidesUrl: '/credit-cards/guides/',
    productPageUrl: '/personal-loans/',
    crossApp: true
  },

  [VERTICAL.REVERSE_MORTGAGE]: {
    icon: getComponent('IconsVerticalsReverseMortgage'),
    home_icon: getComponent('IconsVerticalsReverseMortgage'),
    title: 'Reverse Mortgage',
    guides_index_title: 'Reverse Mortgage',
    label: 'Reverse Mortgage',
    cta_id: 'reverse_mortgage_btn',
    text: '',
    url: 'https://lp.compareclub.com.au/home-loan/reverse-mortgage/',
    guidesUrl: '',
    productPageUrl: 'https://lp.compareclub.com.au/home-loan/reverse-mortgage/',
    productPage: 'https://lp.compareclub.com.au/home-loan/reverse-mortgage/',
    crossApp: true,
    includeUTMs: true,
    schedule: 'Mon-Fri Friday 9am-6pm AEST',
    schedule_weekend: ''
  },
  OVHC: {
    icon: getComponent('IconsVerticalsOVHC'),
    home_icon: getComponent('IconsVerticalsOVHC'),
    title: 'Overseas Visitors Cover',
    guides_index_title: 'OVHC',
    label: 'OVHC',
    cta_id: 'ovhc_quote_btn',
    text: '',
    url: '/health-insurance/visitors-visa-ovhc/',
    guidesUrl: '',
    productPageUrl: '/health-insurance/visitors-visa-ovhc/',
    productPage: '/health-insurance/visitors-visa-ovhc/',
    crossApp: false,
    schedule: 'Mon-Fri Friday 9am-6pm AEST',
    schedule_weekend: ''
  },

  [VERTICAL.CAR_LOANS]: {
    comparisonPage: '',
    customQuickQuotePage: false,
    dealAlertsFormPage: '/dashboard/deal-alerts/form/car-loans/',
    formPage: '/personal-loans/quote/form/step1',
    logSource: '',
    phoneNumber: '1300 190 429',
    productPage: '/car-loans/',
    requestCallbackTankyouMessage: 'We have received your callback request. One of our <strong>experts</strong> will be in touch with you.',
    requestCallbackText: 'Our experts can provide you with free personal advice. Let us call you.',
    schedule: 'Mon-Fri 8am-8pm AEST',
    schedule_weekend: '',
    speakWithUsText: 'Our experts can provide you with free personal advice.',
    stickyCta: false,
    title: 'Car Loans',
    verticalTabletTitle: '',

    icon: getComponent('IconsVerticalsCarLoan'),
    home_icon: getComponent('IconsVerticalsHomeCarLoan'),
    guides_index_title: 'Personal Finance',
    label: 'Car Loans',
    cta_id: 'car_loans_quote_btn',
    text: 'Get a quote in less than a minute.',
    url: '/personal-loans/quote/form/step1',
    guidesUrl: '/credit-cards/guides/',
    productPageUrl: '/car-loans/',
    crossApp: true
  },

  [VERTICAL.CAR_INSURANCE]: {
    comparisonPage: '',
    customQuickQuotePage: false,
    dealAlertsFormPage: '/dashboard/deal-alerts/form/car-insurance/',
    formPage: '/car-insurance/quote/form/registration/',
    logSource: '',
    phoneNumber: '',
    productPage: '/car-insurance/',
    requestCallbackTankyouMessage: '',
    requestCallbackText: '',
    schedule: '',
    schedule_weekend: '',
    speakWithUsText: '',
    stickyCta: false,
    title: 'Car Insurance',
    verticalTabletTitle: '',

    icon: getComponent('IconsVerticalsCarInsurance'),
    home_icon: getComponent('IconsVerticalsHomeCarInsurance'),
    guides_index_title: 'Personal Finance',
    label: 'Car Insurance',
    cta_id: 'car_insurance_quote_btn',
    text: 'Get a quote in under 2 minutes.',
    badgeInfo: 'New',
    badgeTheme: 'primary',
    url: '/car-insurance/',
    guidesUrl: '/credit-cards/guides/',
    productPageUrl: '/car-insurance/',
    crossApp: false
  },

  [VERTICAL.CREDIT_CARDS]: {
    comparisonPage: '',
    customQuickQuotePage: false,
    dealAlertsFormPage: '/dashboard/deal-alerts/form/credit-cards/',
    formPage: '/credit-cards/',
    logSource: '',
    phoneNumber: '',
    productPage: '/credit-cards/',
    requestCallbackTankyouMessage: 'We have received your callback request. One of our <strong>experts</strong> will be in touch with you.',
    requestCallbackText: 'Our experts can provide you with free personal advice. Let us call you.',
    schedule: '',
    schedule_weekend: '',
    speakWithUsText: 'Our experts can provide you with free personal advice.',
    stickyCta: false,
    title: 'Credit Cards',
    verticalTabletTitle: '',

    icon: getComponent('IconsVerticalsCreditCard'),
    home_icon: getComponent('IconsVerticalsHomeCreditCard'),
    guides_index_title: 'Personal Finance',
    label: 'Credit cards',
    cta_id: 'credit_cards_quote_btn',
    text: 'Get a quote in less than a minute.',
    url: '/credit-cards/',
    guidesUrl: '/credit-cards/guides/',
    productPageUrl: '/credit-cards/',
    crossApp: false
  }
}
