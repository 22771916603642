
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexvvETDd_45pagYQ2kYOtxWpLa8MO9BJ9_45bULZf0bqLkOywMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginmsdbUvygo_HT0iIJPNPnVvzrPVRx8yVv_uA1CFZOr_45kMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyevrXIOc50u0HNfuo_45_45VJobQkrvF2zV_45eEHD6cUe2EroMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapSyYpXYoUNscbbJM6yhkzhty4UzCrbpJ2CbjTOlfTQhoMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usW_45DH5TRJMGAT_452iTgWMFljBJbhNZGkgQyFn7uhng0SQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerdpNAd9etryCnc_e_4564BD_cz_45lPhih3XpiX2DA6kP6_MMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintsn_2GeKjlqeSIpjVX3YHMuNKrzSRy4xU_45Kz1IvdKQpk8Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usGo_8rgb1U1MBQ5eyMe_HPvwCIGEemoqHB1gxQLlj_45WMMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexiHiH3Z_45xIB4J6_vAnkL8j6CcEiU7sMeQZMp7z5zg284Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexZXTFZurUQpmbQ8g0_qR31U9LAYu7SbW40AiMkdnh_4580Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93BUNYUu3GQYgcBWBZaAK4AJGtdCu7kFvdUbW4HuCTJmYMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsvLWmA9XCeK_4FGZ_UX7mAuL6VukyD5MRsJrPmx2BVrAMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useXplS9lv4hsDVI7oRK9pYjZxYOl_GBpSWrTtRFB2hzYAMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as index7ijtFmeSWQNPaSTh9ZEcSJxy2fbtlxkAVrupIHxmydUMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policy8gANAHRR6KvJ4oV6QeBNerY63tboFmOOUa1rY_45claoUMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordFgZ38dHV1qRAkp9Siz_45RCacCYm3zG1ck_45MW_1nyXhVAMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexPTiid6CGMOyrdH_45RHoV2fKjqfyFr_Mylhj_4534lvnDKkMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexoLkHzhdXhTxDuFADqZ0uwjRuRQcsXClk_45sUux8Y2W9gMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45password_tQN8ZKhRQlL8jOnsRkdP150R_45xgzT4ex4z5taHhJ_kMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as indexiA30LwV8Bras4llnwXEeA8lJHbZLVdN_FZwQiJ3mDPUMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93Qpo_pZpsEIBltkW9G5Q6pTrVn0trfTHDua54cWdPie4Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesiQoO_xl7I0jB8E01VxdE47NR0yaEmX7D4R4esNwnvCAMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticeez9a7tZjtWPHsTZXATs0L_45SFh7lsu6BFwbId8pISDnIMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexwTVzLPix4J_FafQ7AcSZ4_45hCCqQo0WJbnJHMj1Jz6NEMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordOLmj_45X9HnLEHTtaXCNXYxNcrjlisxS0_KEfbWRkVTC4Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backXopYSKyE6089uUI8NJKBk1Z4W6uKv769920SLmzr7ToMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as merge47ELLI1rQjZqYn3ddI686Np6eNFgArfNtzi7MSXp4pQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexoCHiRmwoYtkTxmq3mE7tApwbjdMYVx2m1Zb9n_PBIBQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93KWjalbOtXjtyQXbVOKe_451xD3_DJw1anYY_p242eKgWIMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youbfgzgiyYJyO0wbv2raMQYdQS522WLpvoKTgqv0QQqswMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as indexfZ6RGkztuijw3Y_45qq_Jlmsya__ZfuhjHltIXclBpF6gMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexWX4hRGRTIIPVyqDi1Y2w8zQQJTgeTWv2KA0OZknvN50Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93RnqR_pO55eADmWKx5r7wmLBMQvD4_450GdZp5seYSWFj8Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexGhikg1IVvFcsO2g3n0XttjadxGPSc8Ku3NtMTpNwZoEMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexOD7rkTBKeKMdn_45EdWcwfY_45mW8b1xT1wntGi7_Sfc6UwMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93G_JCMMTJgzJXgWbChqFGz_45GihofDMBUmFMRdnPn_55EMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callback_CAwtA_45nnaLD_457qD9qLtrSFpNsPS4XHiXfweHCPok_45EMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claim8U_y8oXEF5MRppCxuhn1CvKoYZ_Yo8qaeuPTWISHAF4Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as indexvkXXLrYMztR3S_0NN0RDfEW8KMlMnwymnGFvWxyyqzoMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youTFvyhden9UUjUzt5eSnqywMTgIJBqkFE_459PAQkDcMxsMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorvaZMJ_45O6fOtAQQ1Gy5OSTdQVWjU7hFEoKFOr0Qj0gmoMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexeRSfFOwtpSyZPNon1IFibQmu7k5UJt8SBaV_45DSdIoRYMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexG_45DGtVHuxq62kttRtcStCWsACQ8ngmGCJslZyYB4hIMMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorQ6JhkmHZ68d9r7YrlQMmCJSa7qYIKk22Mph4FzceA2EMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93Mlpo_45sG4_POz_yzlL_45mTQawADohZJVrbt9NpHuCqW1gMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsF6v8s3i8QOV_45uX8XW2XDW540mJRqS4AX79czgfoQW8QMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45rates8eiFhcoayGLcp4RhWvLTaPZrJQuBV5ucpVmqcmKRwicMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93G3UwibQRglokUMYJLXJk6DTx_PwSrA_dHWq45SbYGQ0Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indextohnUJECj_aY7yYDbxDPfFijdhxUiDy17VW0AYV9h8UMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsTg41TnXoDjGY8_8hfK33mTc2vv5bg7tGWsLrRklYfT8Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexKgI15RgoceZs9VXNo6g4Nt17UWGDkLGVIUFZTLcNggQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chatNi1aAx4T6_Jw8Zu699XfE_ThGrUSju1uW8loQuHc6rEMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45salerikLnlPlfiWQFADqNca_7bOjGnELTx_45WaQOWdt_45RUJMMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youw9kulrCt3f3cvgnbNE3KoAexcQ315VijkVpl3cmHFhYMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensivec6rkuchAPRgLJ95y43XifSivXLlsy2j62w9rk7XHUuoMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93690ePrVtUMOE6Vry8923quCVqUj9BYtDq562MXt_457goMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addhaV2OIQ0eNkJwcmlRIQYqlqKfIUsVNZYgPE9EUdPkzkMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexwsnHLtvu_Km5Br0360I0CBsTI5m3IZOC4hhp14_7NtQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_931JGeI84q1rhGZhoDgn91M0iCtAROgYqPV5v0B1UOtFIMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackme8Pavb7D40IqAXzM8LOnnmulYErwyd6Tvv9nPPC2TQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93hr3nfkaklcqsyZNEuTNKdrC5u0uzAGMKbIrwAowlkVMMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youmR6EkI6AVBhWQ8hcd20ZlMeuzQw_HJXutJxAjNf_45VMEMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45b1o_jfhmPCvMWee6wgSOgfWzvwSIt8ct0ComNphjzBzgMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexZHgFZrBGfprWZcgsLSlaZ2wti3x3thCmmPtYSOSFzfEMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93Aif28inRGmy8m1JDnEy83hXVx_pRQklYREyvJatPHUQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93lr4ZI_Su62j43j_RW0FybXP3vNNTgs5r1ztUcEbMURMMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as index5jO_45ZS65TZnshTbUcdWxMN2ddg0h4Vsz0Z816ejaceAMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addfWLbt4D5lpIuxEllyBVggitFZUFxyyyEsv1AqjnnfXAMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93HDufxkD2eqwSPsosp0JSJux_45SnFVvbHv8SPSqwGOUxcMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailskrCAwQV1HRnKVjhoL3mIVDiEi7E9Vh0ahkf4yyx_RB0Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as digital_45eicVpqkG_45y6yoxS3cbAlAWG9_18Vf4_45p8mYM1j3gpE_dxoMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45you9d5nBrmpdgtQ4uOMLcofa3xLlM0ZI61Xw_rhUsHQb_8Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93ZwDMTN2CZKlUZwPmtJiSjFtWnJ4qudYOsuT_dgJZrJYMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93G6Zvmzw0oDADw3J_Waoj3cb4kcZxDxLG3Mt3ozHrWW8Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as step0gOi_COBOYeI0cfz6bJBUaOdi_G77Tu1QkW2DVxymMp4Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as detailsVqyqgcrQ81BlnQDeUpoXMUyFFaOKiaobxDOZxG_dclMMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsPHyAU0upXta4ig0pTJLDcUnjct_QKq5zaSRa50O_45WtEMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexGSBpgc9ShX8qgK4VcouDzux15md_45y5870uOL59bC3okMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupC2wOp8i2jVnjdm6LIejoJPHTtTNupdhxv5_1pVUF_45_45kMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45you6X1j6YV6Lyubpi98tFzqBzTuIXP_Uf4V4iv3VGZcb_AMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93JgVityrUiG2Hc79kSt3vvIJC8JHOCa_459b61Jl_ZMaocMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as _91step_93n2JkXO5yrRKAKiJ54qK3WlYGYr0Y4WTwu__k9UR695kMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93HDPeajzDDE0qP7UJX4gDxKnUpn9_45uj0dLC_eNtBqq_45AMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step07PXH08c0B_45syrwPMjM6V0eziZ6WhJH8PEC7RDKgO8fUMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customers7t_451uAPxw2AlQgbTBRtjn_mnFDlsT6d09_451rJH_452XYEMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youm2gh7OXpiHzjYQ8PSDncoZ7gI7_45_dJIIKmPtoJoW4JsMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsiOUr1LdPkufvEP4yABQLDwzb4GAkW8m56Oc0ScdzYCsMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailsD04_dalmcLoviqPG3Xo8aS8EJsbw1Z8eFveXrWqsNtUMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93VP9JTu675ximw0tL2qGtjaFPCca2MkQCdkWwLwhvInkMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebate5nMM5O2FuJjxKgOYvYNDK4567UTSyXK0mnOYOXpaJOUMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93mxlRtpygxjbS3FjwCQWMJmLh5NmBfYs8TcfAXykqgrQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsrCrQUwKg2oFMDddwVSoCcJM4r86H9dpWRQNIFDAQPlQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0hDka9edAfHX_E9RuVaoH2D8biNpOV4aXsEA3628R6nsMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as index_45DQmq6raBf9mm1RNfKJg_45UJ9zM_6hxj7qHBIAvKXA6oMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexqU8tMovxZRD_WezRQJzjbG_45NQHbR8ONz1De5RYNqzUwMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailspbGuK7Fg21LnGLIGZjzYiChWpmkwaHAGNW4nTqIGq74Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsA1dxBFrfYluuCwx_t22NvNk1a0qCBAPhXcM0m_45l_XZ4Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as indexElb_LdkL_BMcte3KWa_45tH5OpGjk_y7GglZ_45EaXrwrNIMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsWh3R4bqm2wI2TM_ANf2GNy2wnmAWyl9ACu8HJ0jAkvkMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexxaZoQvfBSjjW1p4n0k3vmTlkiP_wtVA_nBqlhO1bvmsMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successlFyCDjXbE_MmjeoqDokXAqAQtkWYpzko9yLvOC1vQPQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offerz8y15JbGgSdakJWGNvN9pbhkUote4d3LX1zsPzSx_2oMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsyYLxhW5tmU_45Gh02nckFY9umXB27Ur1eeJbtXYfjWkEwMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourZroFtQhWPUFcPKJPgXbBchzuQohbZj8raJkZICmCkakMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93_45GHdtDusWuL5lfzkp5lKcLTfnwT3L_2aAYGKaFsGfs4Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexNdDojtACthd1PHy462qpNJ1oIhpDk8_452AvGg7tNbmBgMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_933g8v8RFoFqL5JEpIWIbeLoEceJEzFhiHg5cwmMq8CnsMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexFmfhD6GPJyJzR7W5_45l1yD5d7yDzA9vvgGg_4Y7BITy4Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_939bcbwjp_45Tg8Ykmomqrv1l0Jan8sB9gRducHgIq7V18sMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as indexoX0kHsqJO9uX4O1ruLKg31eBDeN6ga_45qxchAWAvrveoMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexCpmv8EuO1P8hmZZntxXSx74vnZvaAFXBX72vV_45IxVOsMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailscEwx8c_45ci2o87DvejRZUiZWt1GLn9pfnBtdRlktjQqEMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredjitWFQWDkwroAq1_452AS7Brb04GROHdr_45H1Ud9AoD54QMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexR2z1WCHNMOqBl1PTqUMuAdqXNs1IyKHe8g08pPhx4uwMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youuqp412KSRU4byC9_45ZrQIlkefHflwyu14wQJtelR3CTcMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsvGJWl_Wm5ZXtc768B7XNueTT8XACr8Km8NXr_45YxROLcMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as confirmationYulAuAzc_456h7_9_45iFiU9WmhOZ0FIqproMeB_0wDHphQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionWU3aYF30AHGUvVn0zO2MtLCM97vNoBEKjt5WaAA1wK4Meta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultskH_45_6OnvpprfjG2qgNmKUesg3nkiXoRdf_45P6Hg_45VjIgMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmations_451tviu7CjI5CjSXDIgRexut9KO3GVDAitUkHLPDPeQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93k0cwlh4SgalmrYbw0JS0_45Wu9cHi_Y4oTx6QuPFlbkUgMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93Au9yabe0P2F3lrY6f_45DXJqXEFB6Fk43rczsofVorLAQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93YaIeL5ue2HTEFQOI_rjFxHDkvA4idH1balTNRWyzx4gMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93XJcwKF8l2ODGqnYo2652TUXck_d2wJLo5DT1aJz2s5AMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexNyzL5DUC6qIcTfR1NNnQYwK9fkh0Dw1rH136nsBOcIQMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsdXDEi5jRJfCs213klRVifVghNhQb1SHBIbyGWoKlcskMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93vI8PxsLR8Bl_45J05mYLakE6U1eXX2ZhD_gvtRIX_45YzWsMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_934eyc52Cs4V3m12sUgHgTAaJIwRgXoL0CeDXp4cIvugwMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexi0Ij35wTY5fd88zAbY8fwKVtVc4FwwAGK9zzZgqophYMeta } from "/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginmsdbUvygo_HT0iIJPNPnVvzrPVRx8yVv_uA1CFZOr_45kMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerdpNAd9etryCnc_e_4564BD_cz_45lPhih3XpiX2DA6kP6_MMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordFgZ38dHV1qRAkp9Siz_45RCacCYm3zG1ck_45MW_1nyXhVAMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexoLkHzhdXhTxDuFADqZ0uwjRuRQcsXClk_45sUux8Y2W9gMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password_tQN8ZKhRQlL8jOnsRkdP150R_45xgzT4ex4z5taHhJ_kMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordOLmj_45X9HnLEHTtaXCNXYxNcrjlisxS0_KEfbWRkVTC4Meta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93KWjalbOtXjtyQXbVOKe_451xD3_DJw1anYY_p242eKgWIMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexGhikg1IVvFcsO2g3n0XttjadxGPSc8Ku3NtMTpNwZoEMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexvkXXLrYMztR3S_0NN0RDfEW8KMlMnwymnGFvWxyyqzoMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexG_45DGtVHuxq62kttRtcStCWsACQ8ngmGCJslZyYB4hIMMeta?.props ?? {"verificationNeeded":true},
    meta: indexG_45DGtVHuxq62kttRtcStCWsACQ8ngmGCJslZyYB4hIMMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsTg41TnXoDjGY8_8hfK33mTc2vv5bg7tGWsLrRklYfT8Meta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexKgI15RgoceZs9VXNo6g4Nt17UWGDkLGVIUFZTLcNggQMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackme8Pavb7D40IqAXzM8LOnnmulYErwyd6Tvv9nPPC2TQMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebate5nMM5O2FuJjxKgOYvYNDK4567UTSyXK0mnOYOXpaJOUMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailspbGuK7Fg21LnGLIGZjzYiChWpmkwaHAGNW4nTqIGq74Meta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerz8y15JbGgSdakJWGNvN9pbhkUote4d3LX1zsPzSx_2oMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsyYLxhW5tmU_45Gh02nckFY9umXB27Ur1eeJbtXYfjWkEwMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93_45GHdtDusWuL5lfzkp5lKcLTfnwT3L_2aAYGKaFsGfs4Meta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionWU3aYF30AHGUvVn0zO2MtLCM97vNoBEKjt5WaAA1wK4Meta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93k0cwlh4SgalmrYbw0JS0_45Wu9cHi_Y4oTx6QuPFlbkUgMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93Au9yabe0P2F3lrY6f_45DXJqXEFB6Fk43rczsofVorLAQMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93XJcwKF8l2ODGqnYo2652TUXck_d2wJLo5DT1aJz2s5AMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsdXDEi5jRJfCs213klRVifVghNhQb1SHBIbyGWoKlcskMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93vI8PxsLR8Bl_45J05mYLakE6U1eXX2ZhD_gvtRIX_45YzWsMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_934eyc52Cs4V3m12sUgHgTAaJIwRgXoL0CeDXp4cIvugwMeta || {},
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src3798436142/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]