<template>
  <div>
    <div 
      class="modal-backdrop left-0 top-[66px] h-full w-full fixed flex bg-transparent-dark overflow-auto z-[999] transition-opacity duration-500 ease-in-out"
      :class="{
        'hidden': isSliderHidden,  /* Hide modal: transparent and hidden */
        'opacity-100 flex': displayNotificationSlider,  /* Show modal: fully opaque and display flex */
        'opacity-0 pointer-events-none': !displayNotificationSlider    /* Hide modal: transparent and hidden */
      }"
    >
    </div>
    <div 
      v-click-outside="onClickOutside" 
      class="notification-slider fixed top-[66px] bottom-0 right-0 h-full bg-gray-10 border-l-1 border-solid border-gray-400 w-full 3xs:max-w-[26.25rem] transition-transform duration-500 ease-in-out"
      :class="{
        'hidden-disabled': isSliderHidden,
        'translate-x-0': displayNotificationSlider,
        'translate-x-full': !displayNotificationSlider
      }"
    >
      <div class="slider flex flex-col relative overflow-x-auto">
        <h2 class="text-dark border-l-[0.375rem] border-primary pl-2 mt-5 mx-5 empty:hidden leading-6 text-lg flex items-center h-fit">
          <strong class="font-bold text-dark whitespace-nowrap pr-2 align-center" data-v-53d9e941="">Notifications</strong>
          <p class="w-full text-center text-sm border-b border-solid border-gray-400 leading-[0.1em] my-4" data-v-53d9e941=""></p>
        </h2>
        <div id="feed"></div>

        <button 
          class="fixed hidden 3xs:flex top-[4.625rem] right-[26.188rem] bg-gray-10 text-sm font-bold p-1 align-middle rounded-l-2xl border-solid border-gray-400 border-y-1 border-l-1 shadow-xl"
          :class="{
            '!hidden': isSliderHidden
          }"
          @click="closeNotificationSlider"
        >
          <IconsClose
            class="align-baseline inline m-auto text-dark"
            type="default"
            width="24"
            height="24"
            fill="#193560"
          />
          <span class="ml-1 my-auto">Close</span>
        </button>
        
        <button 
          class="w-12 h-12 rounded-full border-1 border-gray-400 bg-white justify-center flex fixed bottom-16 mx-auto mb-[1.25rem] 3xs:hidden close-notification-btn-mobile" 
          @click="closeNotificationSlider"
        >
          <IconsClose
            class="align-baseline inline m-auto text-dark"
            type="default"
            width="30"
            height="30"
            fill="#193560"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'MoleculesNavNotificationSlider'
})

const userStore = useUserStore()

const currentUserId = ref(null)
const displayNotificationSlider = ref(false)
const isSliderHidden = ref(true)
const { $bus } = useNuxtApp()

$bus.off('toggleNotifications')
$bus.on('toggleNotifications', () => {
  displayNotificationSlider.value = !displayNotificationSlider.value
  // Trigger braze to show/hide content cards
  if (displayNotificationSlider.value) {
    showNotificationSlider()
  } else {
    closeNotificationSlider()
  }
})

const showNotificationSlider = () => {
  displayNotificationSlider.value = true
  window?.braze?.showContentCards(document.getElementById('feed'))
}

const closeNotificationSlider = () => {
  window?.braze?.hideContentCards()
  displayNotificationSlider.value = false
}

const onClickOutside = event => {
  const isClickOutside = event?.target?.classList.contains('modal-backdrop')
  if (
    isClickOutside || 
    (
      !event.target.closest('.notification-slider') && 
      !event.target.closest('button')?.classList?.contains('notification-btn')
    )
  ) {
    closeNotificationSlider()
  }
}

const waitForBraze = () => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (typeof window !== 'undefined' && window.braze) {
        clearInterval(interval)
        resolve()
      }
    }, 500)
  })
}

onMounted(async () => {
  try {
    const userID = userStore.getUserProfile?.hash_id

    await waitForBraze()
    if (userID && userID !== currentUserId.value) {
      window.braze.changeUser(userID)
      currentUserId.value = userID
      window.braze.openSession()
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error waiting for Braze:', error)
  }
})

function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

watch(displayNotificationSlider, async value => {
  const body = document.querySelector('body')
  if (value) {
    body.style.overflow = 'hidden'
  } else {
    body.style.overflow = ''
  }

  await nextTick()
  // Timeout after .25 second to hide the slider
  if (value) {
    isSliderHidden.value = false
  } else {
    // Using async/await to create a delay instead of setTimeout
    await sleep(250)
    isSliderHidden.value = true
  }
})

</script>

<style lang="scss">
.notification-slider {
  box-shadow: -4px 6px 10px rgba(0, 0, 0, 0.1);
  z-index: 2147483002;
  .slider {
    height: calc(100% - 66px);
  }

  .ab-feed{
    width: 100%;
    box-shadow: none;
    z-index: 2147483002;
    position: relative;
    .ab-feed-body{
      border: none;
    }
    &.ab-effect-slide {
      -webkit-transform: translateX(0) !important;
      -moz-transform: translateX(0) !important;
      -ms-transform: translateX(0) !important;
      transform: translateX(0) !important;
    }
    .ab-feed-buttons-wrapper {
      display: none !important;
    }
  }
}
.close-notification-btn-desktop {
  box-shadow: 0px 3px 15.6px 0px #19356052; 
}
.close-notification-btn-mobile {
  box-shadow: 0px 3px 15.6px 0px #19356052; 
  right: calc(50% - 24px);
  z-index: 2147483003;
}
</style>