// https://nuxt.com/docs/guide/going-further/runtime-config
export const envKeysPrivate: Record<string, any> = {
  // Apikeys
  apikeyHealthInsurance: '',
  apikeyLifeInsurance: '',
  apikeyIncomeProtection: '',
  apikeyEnergy: '',
  apikeyOvhcInsurance: '',
  apikeyCarInsurance: '',
  apikeyHomeLoans: '',
  apikeyEmail: '',
  apikeyRecaptcha: '',
  apikeyPartnerNewClient: '',
  apikeyPersonalLoans: '',

  ctfSpaceId: '',
  ctfEnvironment: '',
  ctfCdaAccessToken: '',
  ctfPreviewAccessToken: ''
}

export const envKeysPublic: Record<string, any> = {
  environment: '',
  devtoolsEnabled: false,

  ctfSpaceId: '',
  ctfEnvironment: '',
  ctfCdaAccessToken: '',
  ctfPreviewAccessToken: '',

  ratesMessageDates: '',
  internalDomainForInlinesHyperlink: '',
  geodataSource: '',
  gtm: {
    id: 'GTM-WNV652L', // Used as fallback if no runtime config is provided
    defer: true,
    enabled: true,
    debug: false
  },

  // Endpoints
  apiEndpoint: '',
  logApiEndpoint: '',

  // Apikeys
  apikeyHealthInsurance: '',
  apikeyLifeInsurance: '',
  apikeyIncomeProtection: '',
  apikeyEnergy: '',
  apikeyOvhcInsurance: '',
  apikeyCarInsurance: '',
  apikeyHomeLoans: '',
  apikeyEmail: '',
  apikeyRecaptcha: '',
  apikeyPartnerNewClient: '',

  sourceCompanies: '',
  creditCardUrl: '',

  carInsurancePartnerOffers: '',

  // Guides
  enableAllGuides: true,

  // Sentry
  sentryDSN: '',
  sentryEnvironment: '',

  // Optimizely
  optimizelyExperimentList: '',
  optimizelyScript: '',
  optimizelySdkKey: '',

  // Legacy websites
  legacyHealthUrl: '',

  enableSmsVerification: true,

  // Payment Gateway & credit card tokenization related
  bamboraCheckoutMerchantId: '',
  bamboraScriptUrl: '',
  hicCryptojsSecret: '',
  hicCryptojsIntvector: '',
  tokenexScriptUrl: '',

  // Club+
  mhEnvironment: '',
  googleClientId: '',
  enquiryWidgetActiveVerticals: 'Health Insurance,Health Insurance Overseas,Life Insurance,Energy,Home Loans,Personal Loans,Car Insurance,Car Loans',
  appcuesId: '',
  mhEnableVaultComparison: true,
  enableContentCardNotifications: false,
  enableGuestLogin: false,

  // Energy
  enableMsat: false,
  enableBillUpload: false,
  enableUsage: false,
  enableCurrentRetailer: false,
  enableAddress: true,
  enablePropertyType:true,

  splitAuthKey: '',
  // Health
  customerAfterHourPopupEnabled: false
}

export const runtimeEnvConfig: Record<string, any> = { ...envKeysPrivate, public: envKeysPublic }
